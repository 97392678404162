import React, { useEffect, useRef } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
  Linking,
  Animated,
} from "react-native";
import { FontAwesome } from "@expo/vector-icons";

import Layout from "../Layout";
import { getColorScheme } from "../ColorScheme";

const BuyMeACoffeeButton = () => {
  const bounceValue = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(bounceValue, {
          toValue: 1,
          duration: 500,
          useNativeDriver: true,
        }),
        Animated.timing(bounceValue, {
          toValue: 0,
          duration: 500,
          useNativeDriver: true,
        }),
      ])
    ).start();
  }, []);

  return (
    <View style={{ alignItems: "center", justifyContent: "center" }}>
      <Animated.View
        style={{
          transform: [
            {
              translateY: bounceValue.interpolate({
                inputRange: [0, 1],
                outputRange: [0, -10],
              }),
            },
          ],
        }}
      >
        <TouchableOpacity
          onPress={() =>
            Linking.openURL("https://www.buymeacoffee.com/dicedine")
          }
        >
          <Image
            source={{
              uri: "https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png",
            }}
            style={{ height: 60, width: 217 }}
          />
        </TouchableOpacity>
      </Animated.View>
    </View>
  );
};

const ResultScreen = ({ route, navigation }) => {
  const result = route.params;
  const colorScheme = getColorScheme();

  const getFilledStars = (rating) => {
    const filledStars = [];
    for (let i = 1; i <= 5; i++) {
      filledStars.push(
        <FontAwesome
          key={i}
          name={i <= Math.floor(rating) ? "star" : "star-o"}
          size={18}
          color="#FF9E67"
        />
      );
    }
    return filledStars;
  };

  const priceLevelText = "$".repeat(result.price_level);

  return (
    <Layout>
      <View style={styles.container}>
        <Image
          source={{
            uri: result?.photos?.[0]?.photo_url,
          }}
          style={styles.banner}
        />
        <TouchableOpacity
          style={{ alignItems: "center" }}
          onPress={() => {
            const url = `https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${result.place_id}`;
            Linking.openURL(url);
          }}
        >
          <Text style={[styles.name, { color: colorScheme.textColor }]}>
            {result.name}
          </Text>
          <Text style={[styles.vicinity, { color: colorScheme.textColor }]}>
            {result.vicinity}
          </Text>
          <View style={styles.ratingContainer}>
            {getFilledStars(result.rating)}
            <Text style={[styles.reviews, { color: colorScheme.textColor }]}>
              ({result.user_ratings_total} reviews)
            </Text>
          </View>
          {result.price_level && (
            <View style={styles.priceLevelContainer}>
              <Text style={styles.priceLevelText}>{priceLevelText}</Text>
              <Text
                style={[
                  styles.priceDescription,
                  { color: colorScheme.textColor },
                ]}
              >
                Price Level
              </Text>
            </View>
          )}
          <Text style={[styles.openingHours, { color: colorScheme.textColor }]}>
            {result.opening_hours?.open_now ? "Open Now" : "Closed"}
          </Text>
        </TouchableOpacity>
        <View style={styles.navigationButtonsContainer}>
          <TouchableOpacity
            style={styles.navigationButton}
            onPress={() => {
              const url = `https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${result.place_id}`;
              Linking.openURL(url);
            }}
          >
            <FontAwesome name="google" size={20} color="#FFFFFF" />
            <Text style={styles.navigationButtonText}>Google</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.navigationButton}
            onPress={() => {
              const lat = result.geometry.location.lat;
              const lng = result.geometry.location.lng;
              const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
              Linking.openURL(url);
            }}
          >
            <FontAwesome name="map-marker" size={20} color="#FFFFFF" />
            <Text style={styles.navigationButtonText}>Directions</Text>
          </TouchableOpacity>
        </View>

        <TouchableOpacity
          style={[
            styles.navigationButton,
            {
              backgroundColor: colorScheme.buttonColor,
              marginTop: 40,
            },
          ]}
          onPress={() => navigation.goBack()}
        >
          <FontAwesome name="arrow-left" size={20} color="#FFFFFF" />
          <Text style={styles.navigationButtonText}>Back to Search</Text>
        </TouchableOpacity>
      </View>
      <BuyMeACoffeeButton />
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  iconContainer: {
    width: 100,
    height: 100,
    borderRadius: 50,
    marginBottom: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    width: 50,
    height: 50,
  },
  banner: {
    width: "100%",
    height: 200,
    borderRadius: 10,
    marginBottom: 20,
  },
  name: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 15,
  },
  vicinity: {
    fontSize: 18,
    color: "#888",
    marginBottom: 15,
  },
  ratingContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 15,
  },
  reviews: {
    fontSize: 18,
    color: "#888",
    marginLeft: 5,
  },
  priceLevelContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 15,
  },
  priceLevelText: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#FF9E67",
    marginRight: 5,
  },
  priceDescription: {
    fontSize: 18,
    color: "#888",
  },
  openingHours: {
    fontSize: 18,
    color: "#888",
    marginBottom: 20,
  },
  navigationButtonsContainer: { flexDirection: "row", gap: 20 },
  navigationButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#961D1B",
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 30,
  },
  navigationButtonText: {
    color: "#FFFFFF",
    fontSize: 18,
    marginLeft: 10,
  },
});

export default ResultScreen;
