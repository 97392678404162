import React, { useEffect } from "react";
import Toast, { BaseToast, ErrorToast } from "react-native-toast-message";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Platform } from "react-native";

import AppNavigator from "./AppNavigator";

export default function App() {
  useEffect(() => {
    if (Platform.OS === "web") {
      if (/Android/i.test(navigator.userAgent)) {
        window.location.href =
          "https://play.google.com/store/apps/details?id=com.dicendine.app";
      } else if (
        /iPad|iPhone|iPod/.test(navigator.userAgent) &&
        !window.MSStream
      ) {
        window.location.href =
          "https://apps.apple.com/gr/app/dice-dine/id6464474718";
      }
    }
  }, []);

  return (
    <SafeAreaProvider>
      <AppNavigator />
      <Toast
        config={{
          success: (props) => (
            <BaseToast
              {...props}
              style={{
                ...props.style,
                borderLeftColor: "green",
                marginTop: 50,
              }}
            />
          ),
          error: (props) => (
            <ErrorToast
              {...props}
              style={{ ...props.style, borderLeftColor: "red", marginTop: 50 }}
            />
          ),
        }}
      />
    </SafeAreaProvider>
  );
}
