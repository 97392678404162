import React, { useState, useRef, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  TextInput,
  TouchableWithoutFeedback,
  Keyboard,
  Animated,
} from "react-native";
import Select from "react-select";
import Toast from "react-native-toast-message";
import { LinearGradient } from "expo-linear-gradient";
import { FontAwesome5 } from "@expo/vector-icons";
import { Picker } from "@react-native-picker/picker";
import Slider from "@react-native-community/slider";
import { Switch } from "react-native-paper";
import * as Location from "expo-location";
import { Platform } from "react-native";
import axios from "axios";

import Layout from "../Layout";
import { getColorScheme } from "../ColorScheme";

function PlacePicker({
  colorScheme,
  selectedPlaceType,
  setSelectedPlaceType,
  placeTypeOptions,
}) {
  const options = [
    { value: "", label: "All Places" },
    ...placeTypeOptions.map((placeType) => ({
      value: placeType,
      label: placeType.replace(/_/g, " ").toUpperCase(),
    })),
  ];

  return (
    <Select
      value={options.find((option) => option.value === selectedPlaceType)}
      onChange={(option) => setSelectedPlaceType(option.value)}
      options={options}
      styles={{
        control: (provided) => ({
          ...provided,
          color: colorScheme.textColor,
          backgroundColor: colorScheme.pickerColor,
          borderColor: colorScheme.iconColor,
          borderWidth: 2,
          borderRadius: 5,
          marginTop: 5,
        }),
        menu: (provided) => ({
          ...provided,
          color: colorScheme.textColor,
          backgroundColor: colorScheme.pickerColor,
          borderColor: colorScheme.iconColor,
          borderWidth: 2,
          borderRadius: 5,
        }),
        placeholder: (provided) => ({
          ...provided,
          color: colorScheme.iconColor,
          font: '16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
          fontWeight: "bold",
        }),
        singleValue: (provided) => ({
          ...provided,
          color: colorScheme.iconColor,
          font: '16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
          fontWeight: "bold",
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected
            ? colorScheme.buttonColor
            : colorScheme.pickerColor,
          color: state.isSelected ? "#FFFFFF" : colorScheme.iconColor,
          font: '16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
          fontWeight: "bold",
        }),
      }}
      menuPortalTarget={document.body}
    />
  );
}

const SearchScreen = ({ navigation }) => {
  const colorScheme = getColorScheme();

  const [selectedPlaceType, setSelectedPlaceType] = useState(null);
  const [selectedPriceRanges, setSelectedPriceRanges] = useState({
    min: "$",
    max: "$$$$",
  });
  const [locationRadius, setLocationRadius] = useState(5);
  const [editableLocationRadius, setEditableLocationRadius] = useState(false);
  const [editedLocationRadius, setEditedLocationRadius] = useState("5");
  const [selectedMinStarRating, setSelectedMinStarRating] = useState(0);
  const [isOpenNow, setIsOpenNow] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);

  const placeTypeOptions = ["restaurant", "bar", "cafe"];
  const priceRangeOptions = ["$", "$$", "$$$", "$$$$"];

  const dicePosition = useRef(new Animated.Value(0)).current;

  const startDiceAnimation = () => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(dicePosition, {
          toValue: 1,
          duration: 500,
          useNativeDriver: false,
        }),
        Animated.timing(dicePosition, {
          toValue: 0,
          duration: 500,
          useNativeDriver: false,
        }),
      ])
    ).start();
  };

  useEffect(() => {
    if (isLoading) {
      startDiceAnimation();
    }
  }, [isLoading]);

  const renderPriceRangeButton = (option) => {
    const isSelectedMin = selectedPriceRanges.min === option;
    const isSelectedMax = selectedPriceRanges.max === option;
    const isInRange =
      priceRangeOptions.indexOf(option) >=
        priceRangeOptions.indexOf(selectedPriceRanges.min) &&
      priceRangeOptions.indexOf(option) <=
        priceRangeOptions.indexOf(selectedPriceRanges.max);

    const buttonStyle = {
      backgroundColor: isInRange
        ? colorScheme.buttonColor
        : isSelectedMin || isSelectedMax
        ? colorScheme.buttonColor
        : "transparent",
      borderColor:
        isInRange || isSelectedMin || isSelectedMax
          ? colorScheme.buttonColor
          : colorScheme.iconColor,
    };
    const textStyle = {
      color:
        isInRange || isSelectedMin || isSelectedMax
          ? "#FFFFFF"
          : colorScheme.iconColor,
    };

    return (
      <TouchableOpacity
        key={option}
        style={[styles.priceRangeButton, buttonStyle]}
        onPress={() => togglePriceRange(option)}
      >
        <Text style={[styles.priceRangeButtonText, textStyle]}>{option}</Text>
      </TouchableOpacity>
    );
  };

  const togglePriceRange = (option) => {
    if (
      selectedPriceRanges.min === option ||
      selectedPriceRanges.max === option
    ) {
      setSelectedPriceRanges({
        ...selectedPriceRanges,
        min: "",
        max: "",
      });
    } else if (!selectedPriceRanges.min) {
      setSelectedPriceRanges({
        ...selectedPriceRanges,
        min: option,
      });
    } else if (!selectedPriceRanges.max) {
      setSelectedPriceRanges({
        ...selectedPriceRanges,
        max: option,
      });
    } else {
      setSelectedPriceRanges({
        min: option,
        max: "",
      });
    }
  };

  const toggleMinStarRating = (rating) => {
    if (selectedMinStarRating === rating) {
      setSelectedMinStarRating(0);
    } else {
      setSelectedMinStarRating(rating);
    }
  };

  const onLocationRadiusPress = () => {
    setEditableLocationRadius(true);
    setEditedLocationRadius(locationRadius.toString());
  };

  const onLocationRadiusChange = (text) => {
    setEditedLocationRadius(text);
  };

  const onLocationRadiusBlur = () => {
    setEditableLocationRadius(false);
    const parsedValue = parseFloat(editedLocationRadius);
    if (!isNaN(parsedValue)) {
      setLocationRadius(parsedValue);
    }
  };

  const renderStarRatingButton = (rating) => {
    const isSelected = selectedMinStarRating === rating;
    const buttonStyle = {
      backgroundColor: isSelected ? colorScheme.buttonColor : "transparent",
      borderColor: isSelected ? colorScheme.buttonColor : colorScheme.iconColor,
    };
    const textStyle = {
      color: isSelected ? "#FFFFFF" : colorScheme.iconColor,
    };

    return (
      <TouchableOpacity
        key={rating}
        style={[styles.ratingButton, buttonStyle]}
        onPress={() => toggleMinStarRating(rating)}
      >
        <Text style={[styles.ratingButtonText, textStyle]}>
          {rating} {rating === 1 ? "Star" : "Stars"}
        </Text>
      </TouchableOpacity>
    );
  };

  const fetchRestaurants = async (filters) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://dicendine.vercel.app/api/place",
        {
          type: filters.placeType,
          location: `${filters.coords.latitude},${filters.coords.longitude}`,
          radius: filters.locationRadius,
          minPrice: filters.priceRanges.min.length,
          maxPrice:
            filters.priceRanges.max.length === 0
              ? filters.priceRanges.min.length
              : filters.priceRanges.max.length,
          minStarRating: filters.minStarRating,
          openNow: filters.isOpenNow,
        },
        {
          timeout: 5000,
          validateStatus: function (status) {
            return status >= 200 && status <= 404;
          },
        }
      );

      setIsLoading(false);

      if (response.data && response.data.name) {
        setNoResults(false);
        navigation.navigate("Result", response.data);
      } else {
        setNoResults(true);
      }
    } catch (error) {
      setIsLoading(false);
      setNoResults(true);
      Toast.show({
        type: "error",
        text1: "Error",
        text2: "Something went wrong, please try again later.",
      });
    }
  };

  const requestLocationPermission = async () => {
    let { status } = await Location.requestForegroundPermissionsAsync();

    if (status === "granted") {
      return true;
    } else {
      Toast.show({
        type: "error",
        text1: "Error",
        text2: "You must grant location permissions to use this app.",
      });

      const retryStatus = await Location.requestForegroundPermissionsAsync();
      if (retryStatus === "granted") {
        return true;
      } else {
        return false;
      }
    }
  };

  const onSearchPress = async () => {
    const permissionGranted = await requestLocationPermission();

    if (permissionGranted) {
      try {
        let location = await Location.getCurrentPositionAsync({});
        const filters = {
          placeType: selectedPlaceType,
          priceRanges: selectedPriceRanges,
          locationRadius: locationRadius,
          minStarRating: selectedMinStarRating,
          coords: location.coords,
          isOpenNow: isOpenNow,
        };

        await fetchRestaurants(filters);
      } catch (error) {
        setNoResults(true);
        Toast.show({
          type: "error",
          text1: "Error",
          text2: "Something went wrong, please try again later.",
        });
      }
    }
  };

  return (
    <>
      <Layout>
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
          <ScrollView contentContainerStyle={styles.contentContainer}>
            <Text style={[styles.title, { color: colorScheme.textColor }]}>
              Select Filters
            </Text>
            <View style={styles.filterContainer}>
              <Text
                style={[styles.filterLabel, { color: colorScheme.textColor }]}
              >
                Location Radius:
              </Text>
              <TouchableOpacity
                style={styles.radiusContainer}
                onPress={onLocationRadiusPress}
              >
                <Slider
                  style={styles.slider}
                  minimumValue={1}
                  maximumValue={30}
                  step={1}
                  value={locationRadius}
                  onValueChange={(value) => setLocationRadius(value)}
                  minimumTrackTintColor={colorScheme.buttonColor}
                />
                <View style={styles.radiusNumberContainer}>
                  <FontAwesome5
                    name="pencil-alt"
                    size={16}
                    color={colorScheme.buttonColor}
                    style={styles.pencilIcon}
                  />
                  {editableLocationRadius ? (
                    <TextInput
                      style={[
                        styles.radiusInput,
                        {
                          color: colorScheme.textColor,
                        },
                      ]}
                      keyboardType="numeric"
                      value={editedLocationRadius}
                      onChangeText={onLocationRadiusChange}
                      onBlur={onLocationRadiusBlur}
                      autoFocus
                    />
                  ) : (
                    <Text
                      style={[
                        styles.radiusNumber,
                        {
                          color: colorScheme.textColor,
                        },
                      ]}
                      onPress={onLocationRadiusPress}
                    >
                      {locationRadius}
                    </Text>
                  )}
                  <Text
                    style={[
                      styles.radiusMinMax,
                      { color: colorScheme.textColor },
                    ]}
                  >
                    km
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
            <View style={styles.filterContainer}>
              <Text
                style={[styles.filterLabel, { color: colorScheme.textColor }]}
              >
                Place Type:
              </Text>
              {Platform.OS === "web" ? (
                <PlacePicker
                  colorScheme={colorScheme}
                  selectedPlaceType={selectedPlaceType}
                  setSelectedPlaceType={setSelectedPlaceType}
                  placeTypeOptions={placeTypeOptions}
                />
              ) : (
                <Picker
                  style={{
                    ...styles.picker,
                    color: colorScheme.textColor,
                  }}
                  selectedValue={selectedPlaceType}
                  onValueChange={(itemValue) => setSelectedPlaceType(itemValue)}
                  selectionColor={`${colorScheme.buttonColor}30`}
                >
                  <Picker.Item
                    label="All Places"
                    value=""
                    color={colorScheme.pickerColor}
                  />
                  {placeTypeOptions.map((placeType) => (
                    <Picker.Item
                      key={placeType}
                      label={placeType.replace(/_/g, " ").toUpperCase()}
                      value={placeType}
                      color={colorScheme.pickerColor}
                    />
                  ))}
                </Picker>
              )}
            </View>

            <View style={styles.filterContainer}>
              <Text
                style={[styles.filterLabel, { color: colorScheme.textColor }]}
              >
                Price Range:
              </Text>
              <View style={styles.priceRangeButtonsContainer}>
                {priceRangeOptions.map((option) =>
                  renderPriceRangeButton(option)
                )}
              </View>
            </View>

            <View style={styles.filterContainer}>
              <Text
                style={[styles.filterLabel, { color: colorScheme.textColor }]}
              >
                Minimum Rating:
              </Text>
              <View style={styles.ratingButtonsContainer}>
                {[1, 2, 3, 4, 5].map((rating) =>
                  renderStarRatingButton(rating)
                )}
              </View>
            </View>

            <View
              style={[styles.filterContainer, { alignItems: "flex-start" }]}
            >
              <Text
                style={[styles.filterLabel, { color: colorScheme.textColor }]}
              >
                Open Now:
              </Text>
              <Switch
                trackColor={{
                  false: "#767577",
                  true: colorScheme.buttonColor,
                }}
                thumbColor={isOpenNow ? "#f4f3f4" : "#f4f3f4"}
                ios_backgroundColor="#3e3e3e"
                onValueChange={() => setIsOpenNow(!isOpenNow)}
                value={isOpenNow}
              />
            </View>
            <TouchableOpacity
              style={[
                styles.button,
                { backgroundColor: colorScheme.buttonColor },
              ]}
              onPress={() => onSearchPress()}
            >
              <Text style={styles.buttonText}>
                Pick {selectedPlaceType || "a Place"}
              </Text>
            </TouchableOpacity>
          </ScrollView>
        </TouchableWithoutFeedback>
      </Layout>
      {isLoading && (
        <LinearGradient
          style={styles.loadingContainer}
          colors={colorScheme.backgroundGradientColors}
        >
          <Animated.View
            style={[
              styles.diceContainer,
              {
                transform: [
                  {
                    rotate: dicePosition.interpolate({
                      inputRange: [0, 1],
                      outputRange: ["0deg", "360deg"],
                    }),
                  },
                ],
              },
            ]}
          >
            <FontAwesome5 name="dice" size={50} color={colorScheme.iconColor} />
          </Animated.View>
          <Text style={[styles.loadingText, { color: colorScheme.iconColor }]}>
            Picking a random place for you...
          </Text>
        </LinearGradient>
      )}
      {noResults && (
        <LinearGradient
          style={styles.loadingContainer}
          colors={colorScheme.backgroundGradientColors}
        >
          <FontAwesome5
            name="meh"
            size={50}
            color={colorScheme.iconColor}
            style={styles.icon}
          />
          <Text style={[styles.loadingText, { color: colorScheme.iconColor }]}>
            Couldn't find any places matching your criteria, modify your filters
            and try again.
          </Text>
          <TouchableOpacity
            style={[
              styles.button,
              { backgroundColor: colorScheme.buttonColor },
            ]}
            onPress={() => setNoResults(false)}
          >
            <Text style={styles.buttonText}>Back</Text>
          </TouchableOpacity>
        </LinearGradient>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    flexGrow: 1,
    justifyContent: "space-between",
  },
  title: {
    marginBottom: 20,
    fontSize: 24,
    fontWeight: "bold",
  },
  filterContainer: {
    paddingVertical: 5,
  },
  filterLabel: {
    fontSize: 16,
    marginBottom: 5,
  },
  sliderContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  slider: {
    flex: 1,
  },
  sliderValue: {
    fontSize: 16,
    marginLeft: 10,
  },
  pencilIcon: {
    marginLeft: 10,
  },
  radiusContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  radiusNumberContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 5,
  },
  radiusNumber: {
    marginLeft: 10,
    fontSize: 16,
  },
  radiusMinMax: {
    marginLeft: 10,
    fontSize: 14,
  },
  radiusInput: {
    padding: 5,
    fontSize: 16,
    width: 30,
    textAlign: "center",
  },
  picker: {},
  priceRangeButtonsContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 5,
  },
  priceRangeButton: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 5,
    marginRight: 10,
    marginBottom: 10,
    borderWidth: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  priceRangeButtonText: {
    fontSize: 16,
    fontWeight: "bold",
  },
  ratingButtonsContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 5,
  },
  ratingButton: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 5,
    marginRight: 10,
    marginBottom: 10,
    borderWidth: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  ratingButtonText: {
    fontSize: 16,
    fontWeight: "bold",
  },
  button: {
    marginTop: 15,
    paddingVertical: 15,
    paddingHorizontal: 40,
    borderRadius: 30,
  },
  buttonText: {
    color: "#FFFFFF",
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    padding: 20,
  },
  loadingText: {
    marginVertical: 30,
    fontSize: 18,
    fontWeight: "bold",
  },
});

export default SearchScreen;
