import { useColorScheme } from "react-native";
import { Platform } from "react-native";

export const getColorScheme = () => {
	const colors = {
		dark: {
			iconColor: "#FFFFFF",
			buttonColor: "#0058A7",
			backgroundGradientColors: ["#000000", "#0A1931"],
			textColor: "#FFFFFF",
			contentBackgroundColor: "transparent",
			pickerColor: Platform.OS === "ios" ? "#FFFFFF" : Platform.OS === "android" ? "#000000" : "#000000",
		},
		light: {
			iconColor: "#961D1B",
			buttonColor: "#0058A7",
			backgroundGradientColors: ["#FFFFFF", "#FFFFFF"],
			textColor: "#000000",
			contentBackgroundColor: "#FFFFFF",
			pickerColor: Platform.OS === "ios" ? "#0058A7" : Platform.OS === "android" ? "#000000" : "#FFFFFF",
		},
	};

	const colorScheme = useColorScheme();
	return colors[colorScheme];
};
