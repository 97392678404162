import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import WelcomeScreen from "./screens/Welcome";
import SearchScreen from "./screens/Search";
import ResultScreen from "./screens/Result";

const Stack = createStackNavigator();

const AppNavigator = () => {
  return (
    <NavigationContainer
      documentTitle={{
        formatter: (options, route) => {
          if (route?.name === "Welcome") {
            return "Dice & Dine - Roll, Discover & Dine Nearby!";
          } else {
            return `${
              route?.params?.name ?? options?.title ?? route?.name
            } - Dice & Dine - Roll, Discover & Dine Nearby!`;
          }
        },
      }}
    >
      <Stack.Navigator>
        <Stack.Screen
          name="Welcome"
          component={WelcomeScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="Search"
          component={SearchScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="Result"
          component={ResultScreen}
          options={{ headerShown: false }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default AppNavigator;
