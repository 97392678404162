import React from "react";
import {
	View,
	Text,
	Image,
	StyleSheet,
	TouchableOpacity,
	Animated,
} from "react-native";
import { FontAwesome5 } from "@expo/vector-icons";

import Layout from "../Layout";
import { getColorScheme } from "../ColorScheme";

const WelcomeScreen = ({ navigation }) => {
	const colorScheme = getColorScheme();

	const renderStep = (iconName, stepText) => (
		<View style={styles.step}>
			<FontAwesome5
				name={iconName}
				size={28}
				color={colorScheme.iconColor}
				style={styles.stepIcon}
			/>
			<Text style={[styles.stepText, { color: colorScheme.textColor }]}>
				{stepText}
			</Text>
		</View>
	);

	return (
		<Layout>
			<View
				style={[
					styles.contentContainer,
					{ backgroundColor: colorScheme.contentBackgroundColor },
				]}
			>
				<View style={styles.logoContainer}>
					<Image
						source={require("../assets/logo.png")}
						style={{ width: 200, height: 200 }}
					/>
				</View>

				<Animated.View style={styles.stepsContainer}>
					{renderStep("filter", "Choose Your Filters")}
					{renderStep(
						"search",
						"Tap to Find a Random Restaurant, Cafe or Bar"
					)}
					{renderStep(
						"glass-cheers",
						"Enjoy Your Randomly Selected Place to Eat, Drink, or Relax"
					)}
				</Animated.View>

				<TouchableOpacity
					style={[
						styles.button,
						{ backgroundColor: colorScheme.buttonColor },
					]}
					onPress={() => navigation.navigate("Search")}
				>
					<Text style={styles.buttonText}>Let's Explore!</Text>
				</TouchableOpacity>
			</View>
		</Layout>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	contentContainer: {
		flex: 1,
		alignItems: "center",
		justifyContent: "space-between",
		paddingHorizontal: 20,
	},
	logoContainer: {
		alignItems: "center",
	},
	icon: {
		marginBottom: 10,
	},
	title: {
		fontSize: 28,
		marginBottom: 5,
	},
	titleBold: {
		fontSize: 38,
		fontWeight: "bold",
		marginBottom: 15,
	},
	stepsContainer: {
		alignItems: "center",
		justifyContent: "center",
		marginBottom: 30,
	},
	step: {
		flexDirection: "row",
		alignItems: "center",
		marginBottom: 20,
		backgroundColor: "rgba(255, 255, 255, 0.1)",
		padding: 12,
		borderRadius: 10,
		width: "90%",
	},
	stepIcon: {
		marginRight: 20,
	},
	stepText: {
		fontSize: 18,
	},
	button: {
		backgroundColor: "#FFC107",
		paddingVertical: 15,
		paddingHorizontal: 40,
		borderRadius: 30,
	},
	buttonText: {
		color: "#FFFFFF",
		fontSize: 18,
		fontWeight: "bold",
	},
});

export default WelcomeScreen;
