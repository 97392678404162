import { Platform, StyleSheet } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { LinearGradient } from "expo-linear-gradient";

import { getColorScheme } from "./ColorScheme";

const Layout = ({ children }) => {
	const colorScheme = getColorScheme();

	return (
		<LinearGradient
			colors={colorScheme.backgroundGradientColors}
			style={styles.container}
		>
			<SafeAreaView style={styles.safeArea}>{children}</SafeAreaView>
		</LinearGradient>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	safeArea: {
		flex: 1,
        paddingVertical: Platform.OS === "ios" ? 0 : 20,
		paddingHorizontal: 20,
	},
});

export default Layout;